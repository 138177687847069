import * as React from 'react';
import { useState, useEffect, Component } from 'react';
import { resolveBrowserLocale, useTranslate, withTranslate } from 'react-admin'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Room from '@mui/icons-material/AccountBoxOutlined';
import PostAdd from '@mui/icons-material/PostAdd';
import Yes from '@mui/icons-material/Check';
import No from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withStyles } from '@mui/material/styles';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    MonthView,
    WeekView,
    Toolbar,
    Appointments,
    AppointmentTooltip,
    EditRecurrenceMenu,
    ViewSwitcher,
    DragDropProvider,
    DateNavigator,
    TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';

import dataProvider from '../dataProvider'

const colors = [
    "silver",
    "black",
    "red",
    "maroon",
    "orange",
    "olive",
    "lime",
    "green",
    "aqua",
    "teal",
    "blue",
    "navy",
    "fuchsia",
    "purple"
]

const getColorByMatch = (match) => {
    const str = `${match.league}`
    let count = 0
    for (let i = 0; i < str.length; i++) {
        count += str.charCodeAt(i)
    }
    return colors[count % colors.length]
}

const Appointment = ({
    children, style, ...restProps
}) => (
    <Appointments.Appointment
        {...restProps}
        style={{
            ...style,
            backgroundColor: getColorByMatch(restProps.data),
        }}
    >
        {children}
    </Appointments.Appointment>
)

const Content = ({
    children, appointmentData, classes, ...restProps
}) => {
    return (
        <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
            <Grid container alignItems="center">
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <Room />
                </Grid>
                <Grid item xs={10}>
                    <span>{appointmentData.district} - {appointmentData.league} - {appointmentData.poule}</span>
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <PostAdd />
                </Grid>
                <Grid item xs={10}>
                    <span>Score: {appointmentData.score1} - {appointmentData.score2}</span>
                </Grid>
            </Grid>
        </AppointmentTooltip.Content>
    )
};

class Calendar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            schedulerData: [
            ],
            name: "Week",
            district: localStorage.getItem('calendar-district'),
            league: localStorage.getItem('calendar-league'),
        }
    }

    initialCall() {
        dataProvider.customs.getMatchDistricts({}).then(({ data }) => {
            this.setState({
                districts: data
            }, () => {
                dataProvider.customs.getMatchLeagues({ district: this.state.district }).then(({ data }) => {
                    this.setState({
                        leagues: data
                    }, () => this.call())
                })
            })
        })
    }

    call() {
        if (this.state.district) {
            dataProvider.customs.calendar({ district: this.state.district, league: this.state.league, current: this.state.current, mode: this.state.name }).then(({ data }) => {
                console.log(data)
                this.setState({
                    schedulerData: [
                        ...data.map(elem => ({
                            ...elem,
                            type: 'match',
                            id: `match-${elem.id}`,
                            title: `${elem.team1} - ${elem.team2}`,
                            startDate: new Date(elem.date_value),
                            endDate: new Date(new Date(elem.date_value).getTime() + 2 * 3600 * 1000),
                        }))
                    ],
                })
            })
        }
    }

    componentDidMount() {
        setTimeout(() => this.initialCall(), 1000)
    }

    renderUI() {
        console.log(
            this.state.schedulerData.reduce((acc, value) => acc.map(elem => elem.sport).includes(value.sport) ? acc : [...acc, value], [])
        )
        return (
            <div>
                <FormControl variant="filled" style={{ margin: "5px", minWidth: 200 }}>
                    <InputLabel>{"District"}</InputLabel>
                    <Select
                        value={this.state.district || localStorage.getItem('calendar-district')}
                        onChange={(event) => {
                            localStorage.setItem('calendar-district', event.target.value)
                            this.setState({ district: event.target.value, schedulerData: [] }, () => this.call())
                        }}
                    >
                        <MenuItem value="">
                            None
                        </MenuItem>
                        {(this.state.districts || [])
                            .map((e) => e.value)
                            .filter(elem => !!elem)
                            .sort((a, b) => a.localeCompare(b))
                            .map(elem => (
                                <MenuItem value={elem}>{elem}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl variant="filled" style={{ margin: "5px", minWidth: 200 }}>
                    <InputLabel>{"League"}</InputLabel>
                    <Select
                        value={this.state.league || localStorage.getItem('calendar-league')}
                        onChange={(event) => {
                            localStorage.setItem('calendar-league', event.target.value)
                            this.setState({ league: event.target.value, schedulerData: [] }, () => this.call())
                        }}
                    >
                        <MenuItem value="">
                            None
                        </MenuItem>
                        {(this.state.leagues || [])
                            .map((e) => e.value)
                            .filter(elem => !!elem)
                            .sort((a, b) => a.localeCompare(b))
                            .map(elem => (
                                <MenuItem value={elem}>{elem}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        )
    }

    renderCalendar() {

        const currentDate = new Date()

        // const commitChanges = ({ added, changed, deleted }) => {
        //     console.log({ added, changed, deleted })
        //     // console.log({ changed })
        //     if (Object.keys(changed).length > 0) {
        //         const id = Object.keys(changed)[0]
        //         const objs = this.state.schedulerData.filter(elem => `${elem.id}` === id)
        //         if (objs.length > 1)
        //             return
        //         const obj = objs[0]
        //         obj.start = new Date(obj.startDate)
        //         obj.end = new Date(obj.endDate)
        //         if (!(obj.start.getFullYear() === obj.end.getFullYear() && obj.start.getMonth() === obj.end.getMonth() && obj.start.getDate() === obj.end.getDate()))
        //             return
        //         changed[id].startDate = new Date(changed[id].startDate)
        //         changed[id].endDate = new Date(changed[id].endDate)
        //         obj.start.setFullYear(changed[id].startDate.getFullYear(), changed[id].startDate.getMonth(), changed[id].startDate.getDate())
        //         obj.end.setFullYear(changed[id].startDate.getFullYear(), changed[id].startDate.getMonth(), changed[id].startDate.getDate())
        //         if (changed[id].startDate.getHours() > 3) {
        //             obj.start.setHours(changed[id].startDate.getHours(), changed[id].startDate.getMinutes())
        //             obj.end.setHours(changed[id].endDate.getHours(), changed[id].endDate.getMinutes())
        //         }
        //         obj.startDate = `${obj.start}`
        //         obj.endDate = `${obj.end}`
        //         dataProvider.customs.updateCalendar({ id: obj.id.split('course-').join(''), start: `${obj.start}`, end: `${obj.end}`}).then(() => {
        //             this.call()
        //         })
        //     }
        // }

        const onEditingAppointmentChange = (value) => {
            console.log("onEditingAppointmentChange")
        }

        const onAddedAppointmentChange = (...args) => {
            console.log("onAddedAppointmentChange", { args })
        }

        const localizationMessages = {
            'en': {
                allDay: 'All Day',
                today: 'Today'
            },
        };

        const getMessages = locale => localizationMessages[locale];

        return (
            <Paper>
                <Scheduler
                    data={this.state.schedulerData}
                    locale={'en' /* resolveBrowserLocale() */}
                >
                    <ViewState
                        defaultCurrentDate={this.state.current || currentDate}
                        onCurrentDateChange={(newDate) => {
                            console.log({ newDate })
                            this.setState({ current: new Date(newDate), schedulerData: [] }, () => this.call())
                        }}
                        defaultCurrentViewName={this.state.name}
                        onCurrentViewNameChange={name => {
                            this.setState({ name, schedulerData: [] }, () => this.call())
                        }}
                    />
                    {/* <EditingState
                    onCommitChanges={commitChanges}
                    onEditingAppointmentChange={onEditingAppointmentChange}
                    onAddedAppointmentChange={onAddedAppointmentChange}
                /> */}
                    <MonthView
                        displayName={"Month"}
                    />
                    <WeekView
                        displayName={"Week"}
                        startDayHour={8}
                        endDayHour={22}
                    />
                    {/* <EditRecurrenceMenu /> */}
                    <Appointments
                        appointmentComponent={Appointment}
                    />
                    <AppointmentTooltip
                        showCloseButton
                        // showOpenButton
                        contentComponent={Content}
                    />
                    <Toolbar />
                    <DateNavigator />
                    <TodayButton
                        messages={getMessages('en' /* resolveBrowserLocale() */)}
                    />
                    <ViewSwitcher />
                    {/* <DragDropProvider /> */}
                </Scheduler>
            </Paper>
        )
    }

    render() {

        return (
            <div>
                {this.renderUI()}
                {this.renderCalendar()}
            </div>
        )
    }
}

export default Calendar