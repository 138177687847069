
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
import { PieChart, Pie, Sector, Cell } from 'recharts'
import { AreaChart, Area } from 'recharts'
import { BarChart, Bar } from 'recharts'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import dataProvider from '../dataProvider'

const { fetchJson } = fetchUtils

const API_URL = process.env.REACT_APP_API_HOSTNAME

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            json: null,
            ago: !localStorage.getItem('ago') ? 1 : parseInt(localStorage.getItem('ago')),
            start: new Date(Date.now() - 24 * 3600 * 1000),
            end: new Date()
        }
        this.agos = [
            [null, "None"],
            [5 * 60, "5 min ago"],
            [15 * 60, "15 min ago"],
            [30 * 60, "30 min ago"],
            [3600, "1 hour ago"],
            [3 * 3600, "3 hour ago"],
            [6 * 3600, "6 hour ago"],
            [12 * 3600, "12 hour ago"],
            [24 * 3600, "24 hour ago"],
            [48 * 3600, "48 hour ago"],
            [72 * 3600, "72 hour ago"],
            [7 * 24 * 3600, "7 days ago"],
            [30 * 24 * 3600, "30 days ago"],
        ]
    }

    componentDidMount() {
        this.call()
    }

    call() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000)
        } else {
            query.start = this.state.start
            query.end = this.state.end
        }
        const url = `${API_URL}/superuser/graph?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            this.setState({ json: JSON.parse(json.body) })
        })
    }

    callProxies() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000)
        } else {
            query.start = this.state.start
            query.end = this.state.end
        }
        const url = `${API_URL}/superuser/graph/proxies?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            this.setState({ json2: JSON.parse(json.body) })
        })
    }

    render() {
        const formatXDate = (d) => {
            d = new Date(d)
            return `${d.getDate()},${d.getHours()}:${d.getMinutes()}`
        }
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        <FormControl variant="filled" style={{ margin: "20px", minWidth: "200px" }}>
                            <InputLabel>Ago</InputLabel>
                            <Select
                                value={this.state.ago}
                                onChange={(event) => {
                                    localStorage.setItem('ago', event.target.value)
                                    this.setState({ ago: event.target.value, json: null }, () => this.call())
                                }}
                            >
                                {
                                    this.agos.map((elem, index) => <MenuItem value={index}>{elem[1]}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        {
                            this.state.ago === 0 && (
                                <TextField
                                    style={{ margin: "20px" }}
                                    id="datetime-local"
                                    label="Start"
                                    type="datetime-local"
                                    value={new Date(this.state.start.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                    onChange={(event) => {
                                        this.setState({ start: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                    }}
                                    // defaultValue="2017-05-24T10:30"
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )
                        }
                        {
                            this.state.ago === 0 && (
                                <TextField
                                    style={{ margin: "20px" }}
                                    id="datetime-local"
                                    label="End"
                                    type="datetime-local"
                                    value={new Date(this.state.end.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                    onChange={(event) => {
                                        this.setState({ end: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                    }}
                                    // defaultValue="2017-05-24T10:30"
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )
                        }
                    </div>
                    <div>
                        <Button
                            label="Remove All Requests"
                            variant='filled'
                            onClick={() => dataProvider.customs.deleteAllRequests().then(() => window.location.reload())}
                        >
                            Remove All Requests
                        </Button>
                    </div>
                </div>
                {
                    this.state.json && (
                        <div>
                            <div style={{ margin: "auto" }}>
                                <div style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px", width: "100%", textAlign: "center" }}>All</div>
                                <div style={{ width: "100%", textAlign: "center" }}>{this.state.json.all.total} requests</div>
                                <PieChart width={200} height={250} style={{ margin: "auto" }}>
                                    <Pie
                                        dataKey="value"
                                        startAngle={360}
                                        endAngle={0}
                                        data={[
                                            { value: this.state.json.all.success, fill: '#82ca9d', name: "Success" },
                                            { value: this.state.json.all.total - this.state.json.all.success, name: "Error" },
                                        ]}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                    />
                                    <Legend />
                                </PieChart>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    this.state.json.ips.map(elem => (
                                        <div style={{ margin: "20px" }}>
                                            <div style={{ fontWeight: "bold", marginBottom: "5px", width: "100%", textAlign: "center" }}>{elem.ip}</div>
                                            <div style={{ width: "100%", textAlign: "center" }}>{elem.total} requests</div>
                                            <PieChart width={200} height={250}>
                                                <Pie
                                                    dataKey="value"
                                                    startAngle={360}
                                                    endAngle={0}
                                                    data={[
                                                        { value: elem.success, fill: '#82ca9d', name: "Success" },
                                                        { value: elem.total - elem.success, name: "Error" },
                                                    ]}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={80}
                                                />
                                                <Legend />
                                            </PieChart>
                                        </div>
                                    ))
                                }

                            </div>
                            {/* <div style={{ margin: "auto", marginTop: "40px", marginBottom: "40px" }}>
                                <LineChart
                                    width={1000}
                                    height={350}
                                    data={this.state.json.requests.map(elem => (
                                        {
                                            "name": formatXDate(elem.createdAt),
                                            "finished": elem.finished,
                                        }
                                    ))}
                                    style={{ margin: "auto" }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="finished" stroke="#82ca9d" />
                                </LineChart>
                            </div> */}
                            {/* <div style={{ margin: "auto", marginTop: "40px", marginBottom: "40px" }}>
                                <AreaChart
                                    width={1000}
                                    height={350}
                                    data={this.state.json.requests.map(elem => (
                                        {
                                            "name": formatXDate(elem.createdAt),
                                            "%": Math.floor(10000 * elem.finished / elem.total) / 100,
                                        }
                                    ))}
                                    style={{ margin: "auto" }}
                                >
                                    <defs>
                                        <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Area type="monotone" dataKey="%" stroke="#82ca9d" fill="url(#color)" />
                                </AreaChart>
                            </div> */}
                            <div style={{ margin: "auto", marginTop: "40px", marginBottom: "40px" }}>
                                <div style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px", width: "100%", textAlign: "center" }}>All</div>
                                <BarChart width={1000} height={350} style={{ margin: "auto" }} data={this.state.json.bars.all.map(elem => (
                                    {
                                        "name": formatXDate(elem.start),
                                        "Success": elem.success,
                                        "Error": elem.total - elem.success
                                    }
                                ))}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Success" fill="#82ca9d" />
                                    <Bar dataKey="Error" fill="#999999" />
                                </BarChart>
                            </div>
                            {
                                this.state.json.bars.ips.map(elem => (
                                    <div style={{ margin: "auto", marginTop: "40px", marginBottom: "40px" }}>
                                        <div style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px", width: "100%", textAlign: "center" }}>{elem.ip}</div>
                                        <BarChart width={1000} height={350} style={{ margin: "auto" }} data={elem.bars.map(elem => (
                                            {
                                                "name": formatXDate(elem.start),
                                                "Success": elem.success,
                                                "Error": elem.total - elem.success
                                            }
                                        ))}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="Success" fill="#82ca9d" />
                                            <Bar dataKey="Error" fill="#999999" />
                                        </BarChart>
                                    </div>
                                ))
                            }
                            {/* <Button
                                onClick={() => this.callProxies()}
                            >
                                Show Proxies
                            </Button> */}
                        </div>
                    )
                }
                {
                    this.state.json2 && (
                        <div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    this.state.json2.proxies.map(elem => (
                                        <div style={{ margin: "20px" }}>
                                            <div style={{ fontWeight: "bold", marginBottom: "5px", width: "100%", textAlign: "center" }}>{elem.proxy || 'null'}</div>
                                            <div style={{ width: "100%", textAlign: "center" }}>{elem.total} requests</div>
                                            <PieChart width={200} height={250}>
                                                <Pie
                                                    dataKey="value"
                                                    startAngle={360}
                                                    endAngle={0}
                                                    data={[
                                                        { value: elem.success, fill: '#82ca9d', name: "Success" },
                                                        { value: elem.total - elem.success, name: "Error" },
                                                    ]}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={80}
                                                />
                                                <Legend />
                                            </PieChart>
                                        </div>
                                    ))
                                }

                            </div>
                            <Button
                                label="Show Proxies"
                                onClick={() => this.callProxies()}
                            />
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Graph
