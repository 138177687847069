
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    FunctionField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    RichTextField,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ReferenceField,
    ImageField,
    BooleanField,
    BooleanInput,
    SearchInput,
    DateTimeInput,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import { useMediaQuery } from '@mui/material';
import Icon from '@mui/icons-material/Book'
import ImportIcon from '@mui/icons-material/Upload'
import ImportButton from '../components/ImportProxiesButton';

export const ScrapperRequestIcon = Icon

const filterArray = [
]

const FilterList = (props) => (
    <Filter {...props}>
        {filterArray}
    </Filter>
)

const ListActions = (props) => {
    console.log(props)
    return (
        <TopToolbar>
            {/* <FilterButton filters={filterArray} />
            <CreateButton {...props} />
            <ImportButton {...props} resource="ScrapperProxy" /> */}
        </TopToolbar>
    );
}

export const ScrapperRequestList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 200]} />}>
        {
            useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
                <SimpleList linkType="show"
                    primaryText={record => record ? `${record.url}` : ''}
                />
            ) : (
                <Datagrid>
                    <TextField source="ip" sortable={false} />
                    <TextField source="proxy" sortable={false} />
                    <TextField source="url" sortable={false} />
                    <DateField source="createdAt" showTime={true} sortable={true} />
                </Datagrid>
            )
        }
    </List>
)
