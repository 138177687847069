
const API_URL = process.env.REACT_APP_API_HOSTNAME

const authProvider = {
    // authentication
    login: ({ username, password }) => {
        const request = new Request(`${API_URL}/superuser/authenticate`, {
            method: 'POST',
            body: JSON.stringify({ login: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        let status = null
        return fetch(request)
            .then(response => {
                status = response.status
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('token', auth.token);
                const redirectTo = new URLSearchParams(window.location.hash.split('#/login').join('')).get("redirectTo")
                if (redirectTo)
                    setTimeout(() => window.location = redirectTo, 1)
                else
                    setTimeout(() => document.location.reload(), 200)
            })
            .catch(() => {
                if (status !== null) {
                    throw new Error('Network error')
                }
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: params => {
        const request = new Request(`${API_URL}/superuser/check_authentication`, {
            method: 'POST',
            body: JSON.stringify({ token: localStorage.getItem('token') }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .catch(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                throw new Error('Network error')
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: params => Promise.resolve(),
}

export default authProvider
